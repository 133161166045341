import { getAiHost } from "../configs/env";
import { IResponse } from "../models/http";
import axios from 'axios';

//const host = "http://localhost:8000"; //getAiHost()
const host = getAiHost();

interface BillingURL {
  billing_url?: string;
}

export const updateIsPayingWorkspace = async (
  workspaceId: string,
  isPaying: boolean,
  token: string
): Promise<BillingURL> => {
  const url = `${host}/payments/grant`;

  const { data } = await axios.post<BillingURL>(
    url,
    {
      workspaceId,
      isPaying,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
};

export interface PurchaseStatus {
  plan: string;
  plan_display_name: string;
  limits: {
    queries: number;
    storage: number;
    files: number;
  };
  sources: {
    file: boolean;
    youtube: boolean;
    dropbox: boolean;
    google_drive: boolean;
    google_drive_sync: boolean;
    onedrive_sync: boolean;
  },
  experiences: {
    max_experiences: number | null;
    search_portal: boolean;
    chatbot: boolean;
    iframe: boolean;
    widget: boolean;
    sdk: boolean;
  }
}

export const getBillingUrl = async (
  workspaceId: string,
  userId: string,
  token: string
): Promise<BillingURL> => {
  const url = `${host}/payments/billing?company_id=${workspaceId}`;

  const { data } = await axios.get<BillingURL>(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

export const getPaymentStatus = async (
  workspaceId: string,
  userId: string,
  token: string
): Promise<PurchaseStatus> => {
  const url = `${host}/payments/status?company_id=${workspaceId}`;

  const { data } = await axios.get<IResponse<PurchaseStatus>>(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (data.error) {
    throw new Error(data.error.message);
  } else {
    return data.data;
  }
};
