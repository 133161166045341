import { Add } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { Fragment, h } from "preact";

import styles from "./styles.module.scss";
import Spinner from "../../spinner";
import { Ref, forwardRef } from "preact/compat";

interface IProps {
  title: string | any;
  iconPlus?: boolean;
  iconArrow?: boolean;
  color: "white" | "blue" | "violet" | "grey";
  onMouseEnter?: (event: MouseEvent) => void;
  onMouseLeave?: (event: MouseEvent) => void;
  onPress: (event: MouseEvent) => void;
  onKeyPress?: (event?: MouseEvent) => void;
  onHover?: (event: MouseEvent) => void;
  processing?: boolean;
  disabled?: boolean;
  minWidth?: string;
  tabIndex?: number;
}

const Button = forwardRef(
  (
    {
      title,
      onMouseEnter,
      onMouseLeave,
      onPress,
      iconPlus,
      iconArrow,
      color,
      processing,
      disabled = false,
      minWidth,
      tabIndex,
      onKeyPress,
    }: IProps,
    ref?: any
  ) => {
    const renderIconRight = () => {
      return <div className={styles.button__arrow} />;
    };
    const renderIconLeft = () => {
      return <Add fontSize="small" className={styles.button__plusIcon} />;
    };

    const click = (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (!disabled) {
        onPress(e);
      }
    };
    const handleEnter = (e: any) => {
      if (e.key === "Enter") {
        e.preventDefault();
        onKeyPress(e);
      }
    };
    return (
      <div
        className={
          disabled
            ? `${styles.button} ${styles.buttonDisabled} ${styles[color]}`
            : `${styles.button} ${styles[color]}`
        }
        onClick={click}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onKeyPress={handleEnter}
        tabIndex={tabIndex}
        ref={ref}
        style={{ minWidth: minWidth }}
      >
        {processing ? (
          <Spinner />
        ) : (
          <Fragment>
            {iconPlus && renderIconLeft()}
            <div
              className={
                color === "grey"
                  ? styles.greyText
                  : color === "white"
                  ? styles.whiteText
                  : styles.button__title
              }
            >
              {title}
            </div>
            {iconArrow && renderIconRight()}
          </Fragment>
        )}
      </div>
    );
  }
);

export default Button;
